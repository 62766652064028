import React, {Component} from "react"
import {Route} from "react-router-dom"
import Month from "./Month"
import DayBig from "./DayBig"
import {routes, toRoute} from "../../utils"
import {RESERVATIONS_FS} from "../../lib/firebase"
import {moment} from "../../lib"
import Note from "./Note";

export default class Calendar extends Component {

  state = {
    reservations: {},
    date: null,
    currentDate: moment(),
    mutat: []
  }


  componentDidMount() {
    this.updateURL(this.props.match.params)
    RESERVATIONS_FS.where("handled", "==", true).onSnapshot(snap => {
      const reservations = {}
      snap.forEach(reservation => {
        reservations[reservation.id] = reservation.data()
      })
      this.setState({reservations})
    })
  }


  UNSAFE_componentWillReceiveProps = ({match:{params}}) => {
    this.updateURL(params)
  }

  updateURL = ({year, month}) => {
    this.setState({currentDate: moment().year(year).month(month-1)})
  }


  render() {
    const {history} = this.props
    const {currentDate, reservations} = this.state

    return (
      <div id="calendar-wrapper">
        <Route
          component={
            () => (
              <Month
                {...{
                  history,
                  reservations,
                  currentDate
                }}
              />
            )
          }
          exact
          path={toRoute(routes.CALENDAR, ":year", ":month")}
        />
        <Route
          exact
          path={toRoute(routes.CALENDAR, ":year", ":month", ":day", "jegyzet")}
          render={({match, history}) => <Note {...{match, history}}/>
          }
        />
        <Route
          exact
          path={toRoute(routes.CALENDAR, ":year", ":month", ":day")}
          render={({match, history}) =>
            <DayBig
              {...{match,
                history}}
            />
          }
        />
      </div>
    )
  }
}