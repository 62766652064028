import {NOTES_FS, TIMESTAMP} from "../../lib/firebase"
import {TODAY} from "../../lib/moment"
import {validateNote} from "../../utils"

export const note = {
  content: "",
  date: TODAY.toDate(),
  created: Date.now(),
  lastHandledBy: ""
}


/**
 * Fetch a note.
 * @param {string} noteId the id of the note to be fetched
 */
export async function fetch(noteId) {
  try {
    let note = await NOTES_FS.doc(noteId).get()
    if (note.exists) {
      note = note.data()
      note.created.toDate()
      note.timestamp.toDate()
      this.setState({noteId, note})
    }
  } catch (error) {
    this.handleSendNotification(error)
  }
}

/**
 * Update the note that is under editing.
 * (Either creating, or editing one.)
 * @param {string} name name of the field
 * @param {object} value value of the field
 * @param {function} [callback]
 */
export function update(name, value, callback) {
  this.setState(({note}) => ({note: {...note, [name]: value}}), callback)
}

export function reset() {
  this.setState({note, noteId: ""})
}


/**
 * Submits the note (either edit or create)
 * @param {object} note The reservation to be submitted
 * @param {string} adminName Name of the logged in admin.
 * @param {string} [noteId] the id of the note, if edited, not created.
 */
export async function handleSubmit(note, adminName, noteId){
  try {

    const updatedNote = {
      ...note,
      timestamp: TIMESTAMP,
      lastHandledBy: adminName
    }

    const error = validateNote(updatedNote)

    if (error) {
      return Promise.reject({code: "error", message: error})
    } else if (noteId) {
      await NOTES_FS.doc(noteId).update(updatedNote)
    } else {
      await NOTES_FS.add(updatedNote)
    }

    return Promise.resolve(true)

  } catch(error) {
    console.log(error)
    return Promise.reject(error)
  }
}


export function subscribe() {
  NOTES_FS
    .onSnapshot(snap => {
      const notes = []
      snap.forEach(note => {
        const id = note.id
        note = note.data()
        note.date = note.date.toDate()
        note.timestamp = note.timestamp ? note.timestamp.toDate() : new Date()

        notes.push({id, ...note})
      })
      this.setState({notes})
    })
}