import React, {useContext, useMemo, useState, useEffect} from 'react'
import Store from '../../db/Store'
import moment from "moment"
import {Card, TextField, Typography, Button, CardActions, CardContent} from '@material-ui/core'
import {NOTES_FS, TIMESTAMP, AUTH} from '../../lib/firebase'

const Note = ({match: {params: {day, month, year}, url}}) => {
  const {notes} = useContext(Store)
  const [value, setValue] = useState("")
  const date = useMemo(() => moment([year, month, day].join("-")), [day, month, year])
  const note = notes.find(note => moment(note.date).isSame(date, "day"))

  useEffect(() => {
    if (note) {
      setValue(note.content)
    }
  }, [url, notes.length, note])

  const handleChange = ({target: {value}}) => setValue(value)

  const handleSubmit = () => {
    const newNote = {
      content: value,
      timestamp: TIMESTAMP,
      lastHandledBy: AUTH.currentUser.displayName,
      date: moment([year, month, day].join("-")).toDate()
    }

    if (note) {
      NOTES_FS.doc(note.id).update(newNote)
    } else {

      console.log(newNote)
      NOTES_FS.add(newNote)
    }
  }

  const handleDelete = () => {
    if (note) {
      NOTES_FS.doc(note.id).delete()
      setValue("")
    }
  }

  return (
    <Card>
      <CardContent>
        <TextField multiline onChange={handleChange} rows="8" value={value} variant="outlined"/>
        <Typography>Szerkesztve: {note ? moment(note.timestamp).format("LLL") : "-"}</Typography>
        <Typography>Utoljára módosította: {note ? note.lastHandledBy: "Még senki"}</Typography>
      </CardContent>
      <CardActions>
        <Button color="secondary" onClick={handleDelete} variant="outlined">Törlés</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">Mentés</Button>
      </CardActions>
    </Card>
  )
}

export default Note