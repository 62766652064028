import React, {useContext} from "react"
import PropTypes from 'prop-types'
import {Link, withRouter} from "react-router-dom"
import {moment} from "../../lib"
import {routes, toRoute} from "../../utils"
import {Tooltip, IconButton, Typography} from "@material-ui/core"
import Store from "../../db/Store"
import AddIcon from "@material-ui/icons/AddRounded"
import EditIcon from "@material-ui/icons/EditRounded"


const Days = ({
  location, isPlaceholder = false, from = 0, to, currentDate, reservations
}) => {

  const {notes} = useContext(Store)
  const days = []
  for (let day = from + 1; day <= to; day++) {
    const dayReservations = {}

    const note = notes.find(note => moment(note.date).isSame(currentDate.clone().date(day), "day"))

    Object.entries(reservations).forEach(([key, {from, to, roomId}]) => {
      const currentDay = currentDate.clone().date(day)
      const dayFrom = moment(from.seconds*1000 || from).startOf("day")
      const dayTo = moment(to.seconds*1000 || to).endOf("day")
      const dateRange = moment.range(dayFrom, dayTo)
      if (dateRange.contains(currentDay)) {
        (Array.isArray(roomId) ? roomId : [roomId]).forEach(r => {
          dayReservations[key+r] = {
            roomId: r,
            from: currentDay.isSame(dayFrom, "day"),
            to: currentDay.isSame(dayTo, "day")
          }
        })
      }
    })

    days.push(
      <Day
        date={currentDate.clone().date(day)}
        {...{
          location,
          key: day,
          isPlaceholder,
          reservations: dayReservations,
          note: note ? note.content : null
        }}
      />
    )
  }
  return days
}

const Day = ({
  reservations, date, isPlaceholder, note
}) => {
  reservations = Object.values(reservations)
  return (
    <li className={`day-tile ${isPlaceholder ? "placeholder" : ""}`}>
      <div className="has-note">
        <Typography noWrap style={{paddingLeft: 8}}>{note}</Typography>
        <Tooltip title={note ? "Jegyzet módosítása" : "Jegyzet felvétele"}>
          <IconButton component={Link} to={toRoute(routes.CALENDAR, date.clone().format("YYYY/MM/DD"), "jegyzet")}>
            {note ?
              <EditIcon /> :
              <AddIcon/>
            }
          </IconButton>
        </Tooltip>

      </div>
      <span
        style={{
          fontSize: 8,
          position: "absolute",
          right: 0,
          bottom: 0,
          margin: 4,
          opacity: .5
        }}
      >{date.clone().format("dddd")}</span>
      {moment().isSame(date, "day") ?
        <Tooltip title="Ma">
          <p className="today">
            {date.clone().format("D")}
          </p>
        </Tooltip> :
        <p>{date.clone().format("D")}</p>
      }
      {reservations.length ?
        <Tooltip title={`Foglalva: ${reservations.map(({roomId}) => roomId).join(", ")}`}>
          <Link to={toRoute(routes.CALENDAR, date.clone().format("YYYY/MM/DD"))}>
            <ul className="reserved-list">
              {reservations.map(({
                roomId, from, to
              }) =>
                <li
                  className={`room-${roomId} ${from ? "from" : to && "to"}`}
                  key={roomId}
                />
              )}
            </ul>
          </Link>
        </Tooltip>
        : null}
    </li>
  )
}

Day.propTypes = {
  reservations: PropTypes.object,
  date: PropTypes.object,
  isPlaceholder: PropTypes.bool
}

export default withRouter(Days)