import React from 'react'
import {TextField, InputAdornment} from '@material-ui/core'

export const Adults = ({onChange, value}) => {

  const handleChange = ({target: {value}}) => {
    const adults = (parseInt(value, 10) || "")
    onChange("adults", adults, true)
  }

  return (
    <TextField
      InputProps={{endAdornment: <InputAdornment>fő</InputAdornment>}}
      fullWidth
      label="Felnőtt"
      min={1}
      name="adults"
      onChange={handleChange}
      type="number"
      value={value || ""}
    />
  )
}


export default Adults

